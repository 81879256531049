#footer {
  border-top-style: solid;
  border-top-width: 8px;
  border-top-color: #0091ea;
}

.pfooter {
  text-align: center;
  width: 100%;
  color: gray;
  line-height: 10px;
}
